import { gnbId } from '../../constants';

export default function gnbMobileGenerater ({ sectionId = '', sectionData = '', search = true, render }) {
  const gnbFooterType = sectionData.gnbHeaderType === 'main' ? 'normal' : sectionData.gnbHeaderType;

  if (sectionId === 'promotion') {
    sectionData.gnbHeaderType = 'normal';
  }

  //console.log('gnbHeaderType :>> ', sectionData.gnbHeaderType);
  //console.log('gnbMobileHeaderType :>> ', sectionData.gnbMobileHeaderType);
  //console.log('GNB render :>> ', render);

  return window.SbsGnbApp('', {
    env: process.env.NEXT_PUBLIC_IS_DEV === 'Y' ? 'dev' : 'real', // TODO: 추후 이부분은 환경변수로 관리 할 것('local', 'dev', 'real')
    platform: 'mobile',
    header: {
      id: gnbId.headerId,
      type: sectionData.gnbMobileHeaderType || sectionData.gnbHeaderType,
      color: 'white',
      section: sectionData.gnbSection || sectionData.gnbMobileSection || '',
      title: {
        text: sectionData.title || '',
        link: sectionData?.mLink || window.location.href
      },
      use: {
        logo: true,
        menu: true,
        search,
        close: true
      },
      render: render.header
    },
    footer: {
      id: gnbId.footerId,
      type: gnbFooterType,
      color: 'white',
      render: render.footer
    },
    use: {
      async: true,
      logging: true
    }
  }).then(function (app) {
    if (sectionData.gnbHeaderType === 'simple') {
      app.event.headerCloseClicked = function (event) {
        window.history.back();
      };
    }

    return app;
  }).catch(function (error) {
    console.error(error);
  });
}
