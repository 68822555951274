// import { useRouter } from 'next/router';
import cookieCutter from 'cookie-cutter';
// import { useEffect } from 'react';

const GOOGLE_TRAN_LANG_COOKIENAME = 'googtrans';
const DISPLAY_LANG_COOKIENAME = 'SBS_DL';

export default function generateTranslate (router, gnbObject) {
  // const router = useRouter();
  const { defaultLocale, locales, locale } = router;
  let previousGtLang = cookieCutter.get(DISPLAY_LANG_COOKIENAME);

  if (!previousGtLang) {
    const expiresDate = new Date();

    expiresDate.setDate(expiresDate.getDate() + 1);
    cookieCutter.set(DISPLAY_LANG_COOKIENAME, defaultLocale, { expires: expiresDate, path: '/' });

    previousGtLang = defaultLocale;
  }

  if (gnbObject) {
    const googleTranslateArea = gnbObject.getTranslate();

    loadGoogleTranslateJS(defaultLocale, locale, locales, googleTranslateArea, router);
  }
}

function getGtLang () {
  const googtrans = cookieCutter.get(GOOGLE_TRAN_LANG_COOKIENAME) || '';
  return googtrans.split('/').pop();
}

function loadGoogleTranslateJS (defaultLocale, locale, locales, googleTranslateArea, router) {
  let displayLangList;

  // user locale값으로 구글 번역 쿠키 설정
  if (defaultLocale === locale) {
    displayLangList = locales.filter(x => x !== defaultLocale);
    cookieCutter.set(GOOGLE_TRAN_LANG_COOKIENAME, '', { expires: new Date(0), path: '/' });
  } else {
    displayLangList = locales;
    cookieCutter.set(GOOGLE_TRAN_LANG_COOKIENAME, `/${defaultLocale}/${locale}`, { path: '/' });
  }

  window.initGoogleTranslateElement = () => {
    window.googleTranslate = window.google.translate.TranslateElement({
      pageLanguage: defaultLocale,
      // layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
      layout: 4,
      multilanguagePage: true,
      autoDisplay: false,
      includedLanguages: displayLangList.toString()
    }, googleTranslateArea.id);
    // document.querySelector('.goog-te-menu-value').setAttribute('role', 'button');  웹접근성일땐 있었던 태그가 번역 태그 위치 바뀌어서 나와서 일단 주석처리함
  };

  const gtranJsLink = document.createElement('script');
  const gnbJsUrl = '//translate.google.com/translate_a/element.js?cb=initGoogleTranslateElement';

  gtranJsLink.type = 'text/javascript';
  gtranJsLink.charset = 'utf-8';
  gtranJsLink.src = gnbJsUrl;
  gtranJsLink.id = 'gnbJs';
  gtranJsLink.async = true;

  document.head.appendChild(gtranJsLink);

  gtranJsLink.addEventListener('load', () => {
    setTimeout(() => {
      observerGoogleTranslateElement(locale, defaultLocale, googleTranslateArea, router);
    }, 2000);
  });
}

// 구글 언어 변경시 화면 reload
function observerGoogleTranslateElement (previousGtLang, defaultLocale, googleTranslateArea, router) {
  const userBrowerLang = window.navigator.language.split('-')[0];
  const observer = new window.MutationObserver(function (mutations) {
    setTimeout(() => {
      const gtCookieLang = getGtLang() || userBrowerLang;

      // 쿠키 변경시 locale로 url 변경
      if (previousGtLang !== gtCookieLang) {
        const expiresDate = new Date();
        expiresDate.setDate(expiresDate.getDate() + 1);

        let toPath = window.location.pathname.replace(`/${previousGtLang}`, '');// + window.location.search;
        let toPathLang = `/${defaultLocale}`;

        if (!toPath.startsWith('/')) {
          toPath = '/' + toPath;
        }

        if (gtCookieLang === defaultLocale) {
          if (userBrowerLang === defaultLocale) {
            toPathLang = '';
            cookieCutter.set(DISPLAY_LANG_COOKIENAME, '', { expires: new Date(0), path: '/' });
          } else {
            const expiresDate = new Date();
            expiresDate.setDate(expiresDate.getDate() + 1);
            cookieCutter.set(DISPLAY_LANG_COOKIENAME, gtCookieLang, { expires: expiresDate, path: '/' });
          }

          previousGtLang = gtCookieLang;

          cookieCutter.set(GOOGLE_TRAN_LANG_COOKIENAME, '', { expires: new Date(0), path: '/' });
        } else {
          toPathLang = `/${gtCookieLang}`;
          cookieCutter.set(DISPLAY_LANG_COOKIENAME, gtCookieLang, { expires: expiresDate, path: '/' });
          previousGtLang = gtCookieLang;
        }

        let redirectUrl = `${toPathLang}${toPath}`;

        if (redirectUrl.endsWith('/')) { 
          redirectUrl = redirectUrl.slice(0, -1) || '/';
        }

        window.location.href = redirectUrl;
      }
    }, 1000);
  });

  observer.observe(document.getElementById(googleTranslateArea.id), {
    childList: true,
    subtree: true
  });
  return previousGtLang;
}
